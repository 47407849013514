import React, { useLayoutEffect, useState } from "react"

// values

// -- local storage
export const FCM_TOKEN = "fcm-token"

export const ALL_VALUE = "all-default"
// This is the way we have the "" value on a Select
export const consvertValueIfAllValue = (v) => (v === ALL_VALUE ? "" : v)

// https://b2btmw.atlassian.net/wiki/spaces/ADPB/pages/281673799
export const NOTIFICATION_TYPES = {
  CMS: "Comissões e Contestações",
  FAT: "Facturas",
  ENC: "Encomendas",
  INS: "Instalações",
  CTR: "Contratos",
  EQU: "Reparação de Equipamentos",
  OPO: "Oportunidades",
  INF: "Pedidos de Informação",
  PRO: "Promoções",
  ADM: "Administração",
}

export const CHANNELS_TYPES = {
  C: "Consumo",
  E: "Empresarial",
  A: "Ambos",
}

// Functions
export const spacingPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || phoneNumber.length < 9) return phoneNumber

  return [
    phoneNumber.slice(0, 3),
    " ",
    phoneNumber.slice(3, 6),
    " ",
    phoneNumber.slice(6),
  ].join("")
}

export const truncateString = (s, len = 30) => {
  if (!s) return s

  if (s.length > len) return `${s.substring(0, len)}...`

  return s
}

export const toFixed = (n = 0, decimalPlaces = 2) => {
  const value = new Number(n)

  return value.toFixed(2)
}

export const compareStringsInsensitively = (s1 = "", s2 = "") =>
  isDefined(s1) && isDefined(s2) && s1.toUpperCase() === s2.toUpperCase()

export const getInitials = (text, limit = Number.POSITIVE_INFINITY) => {
  if (!text) return

  const initials = text
    .split(" ")
    .map((n) => n[0])
    .join("")
    .substring(0, limit)

  return initials.toUpperCase()
}

export const isDefined = (v) => v !== undefined && v !== null
export const getN_MonthsAgo = (nMonths) => {
  const date = new Date()
  date.setMonth(date.getMonth() - nMonths)
  return date
}

export const thereIsAnAuthenticatedUser = () => {
  return !!localStorage.getItem("jwt")
}

// Pagination

export const getStartRow = (currentPage, step) => (currentPage - 1) * step + 1
export const getNumberOfPages = (totalItems, limit) =>
  Math.ceil(totalItems / limit)

export const toFixed2Comma = (n) => {
  var formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",

    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  })

  return formatter.format(n)
}

export const findWidth = (widthSize, componentProducts) => {
  if (widthSize <= 950 && componentProducts) return 12

  if (widthSize <= 950 && !componentProducts) return 12

  if (widthSize <= 1210) return 6

  if (widthSize >= 1455) return 3

  return 4
}

export const findWidthActiveCampaign = (widthSize) => {
  if (widthSize <= 850) return 12

  if (widthSize <= 1345) return 6

  if (widthSize >= 1745) return 3

  return 4
}

export const useWindowSize = () => {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

export const useWindowHeight = () => {
  const [size, setSize] = useState(0)
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerHeight)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])
  return size
}

export const widthLessOrEqual = (widthSize, width, less, most, notEqual) => {
  if (notEqual) return widthSize < width ? less : most

  return widthSize <= width ? less : most
}

export const findWidthWithFilters = (widthSize) => {
  if (widthSize <= 520) return 12

  if (widthSize <= 775) return 6

  if (widthSize <= 915) return 4
}

export const formatDate = (date, format = "ymd", join = "/") => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = d.getHours(),
      minute = d.getMinutes();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  if(format == "ymd")
    return [year, month, day].join(join);
  else if (format == "dmy")
    return [day, month, year].join(join);
  else if (format == "dmyhm") {
    var res = [day, month, year].join(join);
    res += ` ${[hour, minute].join(":")}`
    return res;
  }
}

export const validateDate = (dateP) => {
  if(dateP == null || dateP == "")
    return false

  var date = formatDate(dateP)
  return (/^(\d{2})\/?-?(\d{2})\/?-?(\d{4})$/.test(date) || /^(\d{4})\/?-?(\d{2})\/?-?(\d{2})$/.test(date))
}

export const FileToBase64 = async (event) => {
  return new Promise((resolve, reject) => {
    const prePayload = {};
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.readAsDataURL(file);
  
    reader.onload = () => {
        prePayload.fileName = file.name;
        prePayload.fileContent = reader.result;
        
        resolve(prePayload);
    }
  })
}

export const RegexOnlyNumbers = (val) => {
  if(new RegExp('^[0-9]*$').test(val))
    return true;
  else
    return false
}

export const RegexForFloat = (val) => {
  var auxStr = val.slice(val.length -1);

  if(/^\d+([,.]{1}[0-9]{1,2})?$/.test(val.toString()) || (auxStr == "," || auxStr == ".") || val.length == 0)
    return true;

  return false;
}

export const FirstLetterUpper = (txt = "") => {
  return txt.charAt(0).toUpperCase() + txt.substring(1);
}

// RETORNA { PARÂMETRO: VALOR }
export const readURL = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (urlSearchParams.toString() || urlSearchParams.toString() != '') {
      var arr = [];
    urlSearchParams.forEach((a,b)=> {
      arr[b] = a;
    });

      var res = Object.assign({}, arr);
      return res;
  }
  return {};
}

// ESPERA RECEBER { PARÂMETRO: VALOR }
export const buildUrl = (obj) => {
  var keys = Object.keys(obj);
  var values = Object.values(obj);

  var build = new URLSearchParams;

  for(let i = 0; i < keys.length; i++) {
      build.append(keys[i], values[i]);
  }

  const fetchParams = new URLSearchParams(window.location.search);
  fetchParams.forEach((a, b)=> {
      var exist = false;
      build.forEach((x, z) => {
          if(z == b) exist = true;
      });

      if(!exist) build.append(b, a);
  });
  
  var toPush = window.location.origin + window.location.pathname;
  if (build.toString()) toPush = toPush + '?' + build.toString();
  window.history.pushState({}, '', toPush);
}